
const API = {
  EMAIL_FORM_ACTION_URL: "https://api.hsforms.com/submissions/v3/integration/submit/5461948/c92a5661-6de4-40db-b626-ac970986501f",
  SEND_CV_URL: "https://impressit.hurma.work/api/v1/candidate",
  CONTACT_US_FORM_URL: "https://api.hsforms.com/submissions/v3/integration/submit/5461948/99462bec-253b-4612-8e42-1b9910a70eef"
}

const AWARDS = {
  CLUTCH_IMPRESSIT: "https://clutch.co/profile/impressit",
  CLUTCH_IMPRESSIT_SUMMARY: "https://clutch.co/profile/impressit#summary",
  GOODFIRMS_IMPRESSIT: "https://www.goodfirms.co/company/impressit",
  WADLINE_IMPRESSIT: "https://wadline.com/impressit",
  UPCITY_IMPRESSIT: "https://upcity.com/profiles/impressit",
  THE_MANIFEST_IMPRESSIT:
    "https://themanifest.com/uk/staff-augmentation/services",
  TOP_DEVELOPERS_IMPRESSIT:
    "https://www.topdevelopers.co/press-releases/finest-500-mobile-app-development-companies-of-november-2022",
  AMPLIFYRE_IMPRESSIT: "https://www.amplifyre.com/company/impressit",
}

const MEMBERSHIPS = {
  TECH_UK: "https://www.techuk.org/",
  LVIV_IT_CLUSTER: "https://itcluster.lviv.ua/en/",
  IT_UKRAINE_ASSOCIATION: "https://itukraine.org.ua/en/home/",
  FORBES:
    "https://www.forbes.com/sites/victoriacollins/2019/10/01/the-ukrainian-tech-industry-and-the-launch-of-the-ukraine-it-creative-fund/",
}

const DONATIONS = {
  UA_HELP: "https://uahelp.monobank.ua/",
  SUPPORT_UKRAINE_NOW: "https://supportukrainenow.org/"
}

const USAGE_POLICY = {
  PRIVACY_POLICY: "https://www.privacypolicyonline.com/"
}

const SHARE_URLS = {
  SHARE_LINKEDIN_URL: "https://www.linkedin.com/shareArticle?mini=true&url=",
  SHARE_TWITTER_URL: "http://twitter.com/share?url=",
  SHARE_TWEET_URL: "https://twitter.com/intent/tweet?text=",
  SHARE_LINKEDIN_OFFSITE: "https://www.linkedin.com/sharing/share-offsite/?url="
}

export const GLOBAL_APPLICATION_EXTERNAL_LINKS = {
  INSTAGRAM: "https://www.instagram.com/impressit_team",
  LINKEDIN: "https://www.linkedin.com/company/impressit",
  YOUTUBE: "https://www.youtube.com/channel/UCjW25p7A1NHBLOzAcxt7Ztg",
  STARTUP_KIT: "https://startup-kit.io",
  IMPRESSIT_FOUNDER_ONE_LINKEDIN_URL: "https://www.linkedin.com/in/romanzomko/",
  IMPRESSIT_FOUNDER_TWO_LINKEDIN_URL: "https://www.linkedin.com/in/andrew-lekh/",
  IMPRESSIT_BLOG_ARTICLE: "https://impressit.io/blog/",
  ...API,
  ...AWARDS,
  ...MEMBERSHIPS,
  ...DONATIONS,
  ...USAGE_POLICY,
  ...SHARE_URLS
}